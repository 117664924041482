@import '../../../assets/style/colors.scss';

@keyframes fade {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.iconlink {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  position: relative;

  >.content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    >.badge {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      min-width: 19px;
      height: 14px;
      padding: 0 2px;
      border-radius: 7px;
      top: -5px;
      left: 18px;
      right: 0;

      >span {
        font-size: 10px;
        font-weight: bold;
      }
    }
  }

  >.tooltip {
    opacity: 0;
    display: flex;
    position: fixed;
    width: inherit;
    height: inherit;
    z-index: 5000;
    filter: drop-shadow(0px 0px 3px rgba(32, 47, 47, 0.36));
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(28px) !important;
    pointer-events: none;
    transition: opacity 0.25s linear;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-color: transparent transparent $bgGreyLight transparent;
      border-width: 8px;
      border-style: solid;
      margin: 0 10px;
    }

    .content {
      z-index: 7500;
      width: max-content;
      max-width: 230px;
      height: auto;
      background-color: $bgGreyLight;
      border: 0;
      border-radius: 4px;
      padding: 8px;
      font-size: 13px;
      font-weight: 400;
      color: $secondaryDark;
    }

    &.left {
      align-items: flex-start;
    }

    &.right {
      align-items: flex-end;
    }
  }

  &:hover {
    background-color: #cccccf;

    .tooltip {
      opacity: 1;
    }
  }

  &.disabled {
    color: #666;
    cursor: not-allowed;

    &:hover {
      background-color: #eee;
    }
  }

  &.small {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    font-size: 20px;

    >.tooltip {
      transform: translateY(20px);
    }
  }

  &.big {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    font-size: 28px;

    >.tooltip {
      transform: translateY(38px);
    }
  }

  &.debug {
    background-color: red;

    >.content {
      background-color: blue;
    }

    >.tooltip {
      background-color: orange;
    }
  }
}