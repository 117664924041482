.educations {
    display: flex;

    >.education {
        width: 400px;
        max-width: calc(100% - 10px);
    }

    &.selected {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        >.education {
            max-width: 100%;
            width: 800px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .education {}
}