@import "../../assets/style/colors.scss";

.layout {
  position: absolute;
  inset: 0;
  font-family: Ubuntu;
  background-image: url('../../assets/images/bg02.jpg');
  background-repeat: repeat;
  //background-size: cover;
  //background-position: center;

  &::before {
    content: '';
    background: linear-gradient(77deg, rgba(0, 0, 0, .9), transparent 85%);
    top: 0;
    bottom: 0;
    left: 0;
    right: 26%;
    opacity: 1;
    position: absolute;
    z-index: 0;
    transition: opacity .5s;
  }

  button {
    padding-left: 2rem;
    padding-right: 2.4rem;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    word-break: break-word;
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.4rem;
    opacity: 0.75;
    background-color: #ffffffb0;

    &:hover {
      background-color: #ffffff80;
    }
  }

  >.topbar {
    position: absolute;
    top: 0;
    left: 0;
    height: 68px;
    width: 100vw;
    padding: 0 50px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .7) 10%, transparent);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    >.left {
      display: flex;
      align-items: center;
      gap: 50px;

      >.name {
        color: #e50914;
        font-size: 25px;
        font-family: 'Bebas Neue';
      }

      >ul.items {
        display: inline-flex;
        list-style: none;
        gap: 20px;

        >li {
          color: #e5e5e5;
          font-size: 14px;
          transition: color 0.5s ease;
          cursor: pointer;

          &.selected {
            color: #f73a3a;
            border-bottom: 1px solid #f73a3a;
          }

          &:hover {
            color: #ff687c;
          }
        }

      }
    }

    >.right {
      display: flex;
      align-items: center;
      gap: 30px;
    }
  }

  >.page {
    margin-top: 68px;
    height: -webkit-fill-available;
    padding: 20px 50px;
    position: relative;
    overflow: hidden auto;

    h1,
    h2,
    h3,
    h4 {
      color: #fff;
      margin-bottom: 25px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, .45);
      font-weight: 400;

      >.icon {
        color: #e50914;
      }
    }

    h1 {
      font-size: 38px;
    }

    h2 {
      font-size: 32px;
    }

    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 18px;
    }

    >.info {
      width: 36%;

      >.row {
        gap: 10px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .layout {
    &::before {
      right: 0;
    }

    >.topbar {
      padding: 0 20px;

      >.left {
        gap: 30px;

        >.navigate {

          >ul.items {
            user-select: none;
            position: fixed;
            top: 70px;
            left: 60px;
            display: flex;
            flex-direction: column;
            list-style: none;
            background-color: #000000dc;
            width: 150px;
            z-index: 200;
            padding: 10px;
            row-gap: 30px;

            &::before {
              content: '';
              border: 10px solid transparent;
              border-bottom: 10px solid #000000dc;
              width: 0;
              height: 0;
              top: -20px;
              left: 65px;
              position: absolute;
            }

            >li {
              color: #e5e5e5;
              font-size: 16px;
              transition: color 0.5s ease;
              cursor: pointer;

              &.active {
                color: #fff;
              }

              &:hover {
                color: #ffffff80;
              }
            }
          }
        }
      }

      >.right {
        gap: 10px;
      }
    }

    >.page {

      padding: 20px 10px;

      >.info {
        width: 100%;
      }
    }
  }
}