.courses {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    margin-right: -10px;

    >.course {
        width: 25%;
        min-height: 130px;
        border-right: 10px solid transparent;

        >.content {
            height: 100%;
            color: #1d3677;
            background-color: #e4e4e4;
            padding: 10px;
            border-radius: 10px;
            font-weight: 100;
            transition: all 0.2s ease-in-out;
            box-shadow: 0 0 1px #000;

            &:hover {
                transform: scale(1.1);
                box-shadow: 0 0 100px #000000;
            }

            >.name {
                font-size: 1rem;
                margin-bottom: 10px;
                font-weight: 700;
            }

            >.row {
                display: flex;
                justify-content: space-between;

                >.school {
                    font-size: 1rem;
                    font-weight: 700;
                    color: #1f8f64;
                }

                >.date {
                    font-size: 1rem;
                    color: #161616;
                }
            }

            >.info {
                list-style: disc;
                list-style-position: inside;
                font-size: 0.8rem;
                color: #000000;

            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .courses {
        >.course {
            width: 100%;

            >.content:hover {
                transform: scale(1.05);
            }
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
    .courses {
        >.course {
            width: 50%;
        }
    }
}