.resumo {
    background-color: #000000a0;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;

    >p {
        color: #fff;
        font-size: 1.2rem;
    }
}